body {
	margin: 0;
	font-family: 'SF-Pro-Display-Regular', -apple-system, BlinkMacSystemFont,
		'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@font-face {
	font-family: 'SF-Pro-Display-Black';
	src: local('SF-Pro-Display-Black'),
		url('./assets/fonts/SF-Pro-Display-Black.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-BlackItalic';
	src: local('SF-Pro-Display-BlackItalic'),
		url('./assets/fonts/SF-Pro-Display-BlackItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-Bold';
	src: local('SF-Pro-Display-Bold'),
		url('./assets/fonts/SF-Pro-Display-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-BoldItalic';
	src: local('SF-Pro-Display-BoldItalic'),
		url('./assets/fonts/SF-Pro-Display-BoldItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-Heavy';
	src: local('SF-Pro-Display-Heavy'),
		url('./assets/fonts/SF-Pro-Display-Heavy.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-HeavyItalic';
	src: local('SF-Pro-Display-HeavyItalic'),
		url('./assets/fonts/SF-Pro-Display-HeavyItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-Light';
	src: local('SF-Pro-Display-Light'),
		url('./assets/fonts/SF-Pro-Display-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-LightItalic';
	src: local('SF-Pro-Display-LightItalic'),
		url('./assets/fonts/SF-Pro-Display-LightItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-Medium';
	src: local('SF-Pro-Display-Medium'),
		url('./assets/fonts/SF-Pro-Display-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-MediumItalic';
	src: local('SF-Pro-Display-MediumItalic'),
		url('./assets/fonts/SF-Pro-Display-MediumItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-Regular';
	src: local('SF-Pro-Display-Regular'),
		url('./assets/fonts/SF-Pro-Display-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-RegularItalic';
	src: local('SF-Pro-Display-RegularItalic'),
		url('./assets/fonts/SF-Pro-Display-RegularItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-Semibold';
	src: local('SF-Pro-Display-Semibold'),
		url('./assets/fonts/SF-Pro-Display-Semibold.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-SemiboldItalic';
	src: local('SF-Pro-Display-SemiboldItalic'),
		url('./assets/fonts/SF-Pro-Display-SemiboldItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-Thin';
	src: local('SF-Pro-Display-Thin'),
		url('./assets/fonts/SF-Pro-Display-Thin.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-ThinItalic';
	src: local('SF-Pro-Display-ThinItalic'),
		url('./assets/fonts/SF-Pro-Display-ThinItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-Ultralight';
	src: local('SF-Pro-Display-Ultralight'),
		url('./assets/fonts/SF-Pro-Display-Ultralight.ttf') format('truetype');
}

@font-face {
	font-family: 'SF-Pro-Display-UltralightItalic';
	src: local('SF-Pro-Display-UltralightItalic'),
		url('./assets/fonts/SF-Pro-Display-UltralightItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'PlayfairDisplay';
	src: local('PlayfairDisplay'),
		url('./assets/fonts/PlayfairDisplay.ttf') format('truetype');
}

@font-face {
	font-family: 'PlayfairDisplay-Italic';
	src: local('PlayfairDisplay-Italic'),
		url('./assets/fonts/PlayfairDisplay-Italic.ttf') format('truetype');
}
